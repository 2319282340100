<script setup>
import { ref, computed, onMounted } from "vue";
import axios from 'axios';
import { useLoading } from 'vue-loading-overlay'
import Layout from "@/layouts/mainfw.vue";
import PageHeader from "@/components/page-header";
import { useFastWay } from "@/state/modules/fastway";
import Dialog from '@/views/components/Dialog.vue';
import Confirm from '@/views/components/Confirm.vue';
import printJS from 'print-js';

const store = useFastWay();
const loader = useLoading();
let dialog = ref(null);
let dialogPlacedOrder = ref(null);
let confirm = ref(null);
let categories = ref([]);
let products = ref([]);
let paymentMethods = ref([]);
let salesmanInfo = null;
let storeInfo = null;
let warehouseInfo = null;
let documentTypeInfo = null;
let felProviderInfo = null;
let placeOrderDisabled = ref(false);
let posLocation = 0;
let posWarehouse = 0;
let posSaleDocument = 0;
let posFELProvider = 0;
let data_local = ref({
  id: 0,
  document: '',
  date: new Date(),
  dueDate: new Date(),
  documentTypeId: 0,
  documentType: '',
  documentTypeName: '',
  documentTypeFunction: '',
  documentTypeFEL: false,
  description: '',
  comments: '',
  vat: 'CF',
  statusId: 'BOR',
  statusName: 'Borrador',
  partnerId: 0,
  customerCode: '',
  invoiceName: 'Consumidor final',
  invoiceAddress: '',
  invoiceCountryId: 0,
  invoiceStateId: 0,
  invoiceCityId: 0,
  invoiceZipCode: '',
  invoicePhones: '',
  shippingName: '',
  shippingAddress: '',
  shippingCountryId: 0,
  shippingStateId: 0,
  shippingCityId: 0,
  shippingZipCode: '',
  shippingPhones: '',
  salesmanId: 0,
  saleTypeId: 'CON',
  currencyId: 1,
  locationId: 0,
  originWarehouseId: 0,
  amount: 0,
  totalPaid: 0,
  uuid: '',
  relatedInvoices: 0,
  saleOrderId: 0,
  saleOrderName: '',
  saleOrderDate: new Date(),
  saleOrderAmount: 0,
  products: [],
  payments: [],
  cashPayments: []
});
let currentTabIndex = ref(0);
let currentCategory = ref(0);
let search = ref('');
const title = "Punto de venta PIT";
const items = [
  {
    text: "Ventas",
    href: "/",
  },
  {
    text: "Punto de venta PIT",
    active: true,
  }
];

const api = computed({
  get() {
    return store.parameters.apiURL;
  }
});

const maxQuantity = computed({
  get() {
    let value = 0;
    const param = store.parametersFW.filter(p => p.parameter == 'PUNTOVENTA' && p.code == 'MAXIMOCANTIDAD');
    if (param && param.length > 0) {
      value = parseInt(param[0].value);
    }
    return value;
  }
});

const allowMixedPayments = computed({
  get() {
    let value = false;
    const param  = store.parametersFW.filter(p => p.parameter == 'PUNTOVENTA' && p.code == 'PERMITIRPAGOMIXTO');
    if (param && param.length > 0) {
      value = (param[0].value.toUpperCase() == 'SI');
    }
    return value;
  }
});

let param  = store.parametersFW.filter(p => p.parameter == 'PUNTOVENTA' && p.code == 'UBICACION');
if (param && param.length > 0) {
  posLocation = parseInt(param[0].value);
}

param  = store.parametersFW.filter(p => p.parameter == 'PUNTOVENTA' && p.code == 'BODEGA');
if (param && param.length > 0) {
  posWarehouse = parseInt(param[0].value);
}

param  = store.parametersFW.filter(p => p.parameter == 'PUNTOVENTA' && p.code == 'TIPODOCUMENTO');
if (param && param.length > 0) {
  posSaleDocument = parseInt(param[0].value);
}

param  = store.parametersFW.filter(p => p.parameter == 'PUNTOVENTA' && p.code == 'PROVEEDORFEL');
if (param && param.length > 0) {
  posFELProvider = parseInt(param[0].value);
}

const productsList = computed({
  get() {
    let list = [];
    if (search.value != '') {
      clearCategory();
      list = products.value.filter(p => {
        return p.name.toUpperCase().includes(search.value.trim().toUpperCase());
      });
    } else if (currentCategory.value != 0) {
      list = products.value.filter(p => p.categoryId == currentCategory.value);
    } else {
      list = [...products.value];
    }
    return list;
  }
});

const change = computed({
  get() {
    return data_local.value.totalPaid - data_local.value.amount;
  }
});

onMounted(async () => {
  const loading = loader.show({ loader: 'bars', color: '#0000FF' }, { after: () => 'Cargando catálogos' });
  await axios
  .post(`${api.value}/stock/products/categories/list`, {
    userId: store.userInfo.userId,
    token: store.userInfo.token,
    orden: 'order',
    posAvailable: 1
  })
  .then(response => {
    if (response.data.success) {
      categories.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar categorías de productos', response.data.message);
    }
    const params = {
      userId: store.userInfo.userId,
      token: store.userInfo.token,
      limit: 100000,
      startPage: 1,
      searchPhrase: '',
      order: 'name',
      orderDirection: 'ascending',
      categoryId: 0,
      statusId: 'ACT',
      posAvailable: 1
    };
    return axios.post(`${api.value}/stock/products/list`, params);
  })
  .then(response => {
    if (response.data.success) {
      products.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar productos', response.data.message);
    }
    return axios.post(`${api.value}/locations/data`, { id: posLocation, context: store.userInfo });
  })
  .then(response => {
    if (response.data.success) {
      storeInfo = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar datos de tienda', response.data.message);
    }
    return axios.post(`${api.value}/stock/warehouses/data`, { id: posWarehouse, context: store.userInfo });
  })
  .then(response => {
    if (response.data.success) {
      warehouseInfo = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar datos de bodega', response.data.message);
    }
    return axios.post(`${api.value}/sale/document_types/data`, { id: posSaleDocument, context: store.userInfo });
  })
  .then(response => {
    if (response.data.success) {
      documentTypeInfo = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar datos de tipo de documento', response.data.message);
    }
    return axios.post(`${api.value}/sale/fel_providers/data`, { id: posFELProvider, context: store.userInfo });
  })
  .then(response => {
    if (response.data.success) {
      felProviderInfo = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar datos de proveedor FEL', response.data.message);
    }
    const params = {
      userId: store.userInfo.userId,
      token: store.userInfo.token,
      limit: 100000,
      startPage: 1,
      searchPhrase: '',
      order: 'order',
      statusId: 'ACT'
    };
    return axios.post(`${api.value}/sale/cash_payments/list`, params);
  })
  .then(response => {
    if (response.data.success) {
      paymentMethods.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar formas de pago', response.data.message);
    }
    return axios
      .post(`${api.value}/sale/partners/list`, {userId: store.userInfo.userId, token: store.userInfo.token, partnerType: 'v'});
  })
  .then(response => {
    if (response.data.success) {
      const partnerData = response.data.data.filter(p => p.salesmanUserId == store.userInfo.userId);
      if (partnerData && partnerData.length > 0) {
        salesmanInfo = partnerData[0];
      }
    } else {
      dialog.value.show('error', 'Error al cargar lista de vendedores', response.data.message);
    }
    resetData();
    loading.hide();
  })
  .catch(error => {
    let errorMessage = '';
    if (error.message) {
      errorMessage = error.message;
    } else if (error.response) {
      errorMessage = error.response.data.ErrorMessage;
    } else {
      errorMessage = 'Error de conectividad al cargar catálogo';
    }
    loading.hide();
    dialog.value.show('error', 'Error al cargar catálogos', errorMessage);
  });
});

const onVATBlur = event => {
  event.preventDefault();
  data_local.value.vat = event.target.value.trim().toUpperCase();
  if (['C. F.', 'C.F.', 'C.F', 'C-F', 'C/F', 'C\\F'].includes(data_local.value.vat)) {
    data_local.value.vat = 'CF';
  }
  if (data_local.value.vat == 'CF') {
    data_local.value.invoiceName = 'Consumidor final';
  } else {
    data_local.value.invoiceName = '';
    const loading = loader.show({ loader: 'bars', color: '#0000FF' }, { after: () => 'Validando NIT, espere por favor' });
    axios.post(`${api.value}/${felProviderInfo.apiSection}/validate_vat`, {
      vat: data_local.value.vat,
      felToken: felProviderInfo.token,
      validateVATURL: felProviderInfo.vatValidationURL
    })
    .then(response => {
      loading.hide();
      if (response.data.success) {
        data_local.value.vat = response.data.data.vat;
        data_local.value.invoiceName = response.data.data.invoiceName;
      } else {
        dialog.value.show('error', 'Error al cargar validar NIT', `No se encontró el NIT ${data_local.value.vat}`);
      }
    })
    .catch(error => {
      loading.hide();
      var errorMessage = '';
      if (error.message) {
        errorMessage = error.message;
      } else if (error.response) {
        errorMessage = error.response.data.ErrorMessage;
      } else {
        errorMessage = 'Error de conectividad'
      }
      dialog.value.show('error', 'Error al cargar validar NIT', errorMessage);
    });
  }
};

const toProducts = () => {
  if (data_local.value.invoiceName == '') {
    dialog.value.show('error', 'Error validando información', 'Debe seleccionar un NIT correcto');
  } else {
    if (data_local.value.active == '') {
      data_local.value.phone = '';
    }
    currentTabIndex.value = 1;
  }
}

const clearCategory = () => {
  currentCategory.value = 0;
}

const setCategory = id => {
  search.value = '';
  currentCategory.value = currentCategory.value == id ? 0 : id;
}

const reloadStock = () => {
  axios.post(`${api.value}/stock/available/warehouse`, {userId: store.userInfo.userId, token: store.userInfo.token, warehouseId: posWarehouse})
  .then(response => {
    if (response.data.success) {
      const initialStock = response.data.data;
      let productStock = [];
      for (let i = 0; i < products.value.length; i++) {
        productStock = initialStock.filter(p => p.productId == products.value[i].id);
        if (productStock && productStock.length > 0) {
          products.value[i].onHand = productStock[0].onHand;
        } else {
          products.value[i].onHand = 0;
        }
      }
    } else {
      dialog.value.show('error', 'Error al cargar existencias iniciales', response.data.message);
    }
  })
  .catch(error => {
    let errorMessage = '';
    if (error.message) {
      errorMessage = error.message;
    } else if (error.response) {
      errorMessage = error.response.data.ErrorMessage;
    } else {
      errorMessage = 'Error de conectividad al recargar existencias';
    }
    dialog.value.show('error', 'Error al recargar existencias', errorMessage);
  });
}

const addToOrder = product => {
  const index = data_local.value.products.findIndex(p => p.id == product.id);
  if (product.stockControl) {
    const loading = loader.show({ loader: 'bars', color: '#0000FF' }, { after: () => 'Validando existencia de producto, espere por favor' });
    // const apivalue = 'http://localhost:8080/v2';
    axios.post(`${api.value}/stock/available/product`, {
      productId: product.id,
      warehouseId: posWarehouse,
      userId: store.userInfo.userId,
      token: store.userInfo.token
    })
    .then(response => {
      loading.hide();
      if (response.data.success) {
        if (index < 0) {
          if (response.data.data.onHand > 0) {
            data_local.value.products.push({
              id: product.id,
              productId: product.id,
              code: product.code,
              name: product.name,
              quantity: 1,
              price: product.price,
              unitPrice: product.price,
              amount: product.price,
              warehouseId: warehouseInfo.id
            });
            calculateAmount();
          } else {
            dialog.value.show('error', 'Error al validar existencia de producto', `No hay disponibilidad de producto ${product.name} (${product.code})`);
          }
        } else {
          const qty = data_local.value.products[index].quantity + 1;
          if (response.data.data.onHand >= qty) {
            data_local.value.products[index].quantity++;
            data_local.value.products[index].amount = data_local.value.products[index].quantity * data_local.value.products[index].price;
            calculateAmount();
          } else {
            dialog.value.show('error', 'Error al validar existencia de producto', `No hay disponibilidad de producto ${product.name} (${product.code})`);
          }
        }
      } else {
        dialog.value.show('error', 'Error al cargar validar existencia de producto', response.data.message);
      }
    })
    .catch(error => {
      loading.hide();
      var errorMessage = '';
      if (error.message) {
        errorMessage = error.message;
      } else if (error.response) {
        errorMessage = error.response.data.ErrorMessage;
      } else {
        errorMessage = 'Error de conectividad'
      }
      dialog.value.show('error', 'Error al cargar validar existencia de producto', errorMessage);
    });
  } else {
    if (index < 0) {
        data_local.value.products.push({
        id: product.id,
        productId: product.id,
        code: product.code,
        name: product.name,
        quantity: 1,
        price: product.price,
        unitPrice: product.price,
        amount: product.price,
        warehouseId: warehouseInfo.id
      });
    } else {
      data_local.value.products[index].quantity++;
      data_local.value.products[index].amount = data_local.value.products[index].quantity * data_local.value.products[index].price;
      calculateAmount();
    }
    calculateAmount();
  }
}

const removeProduct = product => {
  const index = data_local.value.products.findIndex(p => p.code == product.code);
  if (index >= 0) {
    data_local.value.products[index].quantity = 0;
  }
  calculateAmount();
}

const calculateAmount = () => {
  const products = [];
  data_local.value.amount = 0;
  data_local.value.products.forEach(item => {
    if (item.quantity < 0) {
      item.quantity = Math.abs(item.quantity);
    }
    if (item.quantity > maxQuantity.value) {
      item.quantity = maxQuantity.value;
    }
    if (item.quantity > 0) {
      item.amount = item.quantity * item.price;
      data_local.value.amount += item.amount;
      products.push(item);
    }
  });
  data_local.value.products = [...products];
}

const calculateTotalPaid = line => {
  data_local.value.totalPaid = 0;
  data_local.value.cashPayments.forEach(item => {
    if (item.amount < 0) {
      item.amount = Math.abs(item.amount);
    }
    if (item.id != line.id && allowMixedPayments) {
      item.amount = 0;
    }
    data_local.value.totalPaid += item.amount;
  });
}

const toPayment = () => {
  if (data_local.value.amount <= 0) {
    dialog.value.show('error', 'Error validando información', 'Orden no puede tener valor cero');
  } else {
    currentTabIndex.value = 2;
    data_local.value.cashPayments.forEach(item => {
      item.amount = 0;
    });
    data_local.value.cashPayments[0].amount = data_local.value.amount;
    data_local.value.totalPaid = data_local.value.amount;
  }
}

const placeOrder = () => {
  if (data_local.value.amount <= 0) {
    dialog.value.show('error', 'Error validando información', 'Orden no puede tener valor cero');
  } else if (data_local.value.totalPaid < data_local.value.amount) {
    dialog.value.show('error', 'Error validando información', 'Total pagado no puede ser menor a valor de orden');
  } else {
    placeOrderDisabled.value = true;
    data_local.value.context = store.userInfo;
    data_local.value.storeId = store.userInfo.storeId;
    data_local.value.createdBy = store.userInfo.userId;
    if (salesmanInfo != null) {
      data_local.value.salesmanId = salesmanInfo.id;
    }
    const loading = loader.show({ loader: 'bars', color: '#0000FF' }, { after: () => 'Colocando orden, espere por favor' });
    axios.post(`${api.value}/sale/invoices/save`, data_local.value)
    .then(response => {
      placeOrderDisabled.value = false;
      if (response.data.success) {
        const felDocument = {
          ...response.data.data,
          vat: data_local.value.vat,
          currencyCode: 'GTQ',
          felToken: felProviderInfo.token,
          validateVATURL: felProviderInfo.vatValidationURL,
          signURL: felProviderInfo.signURL,
          certificateURL: felProviderInfo.certificationURL,
          invoiceType: documentTypeInfo.felDocumentType,
          affiliationType: documentTypeInfo.felVATAffiliation,
          branch: storeInfo.felCode,
          senderEmail: storeInfo.email,
          senderVAT: storeInfo.vat,
          commercialName: storeInfo.commercialName,
          senderName: storeInfo.name,
          senderAddress: storeInfo.address,
          senderZipCode: storeInfo.zipCode,
          senderCity: storeInfo.cityName,
          senderState: storeInfo.stateName,
          senderCountry: storeInfo.countryCode
        }
        return axios.post(`${api.value}/${felProviderInfo.apiSection}/create`, felDocument);
      } else {
        throw response.data.message;
      }
    })
    .then(response => {
      if (response.data.success) {
        if (typeof response.data.data.signDate == "string") {
          response.data.data.signDate = new Date(response.data.data.signDate);
          }
        return axios.post(`${api.value}/sale/invoices/fel_update`, {
          id: response.data.data.id,
          certificationDate: response.data.data.signDate,
          satuuid: response.data.data.satUuid,
          certificationSerial: response.data.data.serial,
          certificationNumber: response.data.data.number,
          sourceXML: response.data.data.signedXml,
          context: store.userInfo
        });
      } else {
        throw `La orden fue colocada pero no pudo ser certificada.   Transacción: ${response.data.data.id} - Error: ${response.data.message}`;
      }
    })
    .then(response => {
      if (response.data.success) {
        return axios.post(`${api.value}/sale/invoices/generate_ticket`, {
          id: response.data.data.id,
          context: store.userInfo,
          createdBy: store.userInfo.userId
        })
      } else {
        throw `Número de transacción: ${response.data.data.id} - Error: ${response.data.message}`;
      }
    })
    .then(response => {
      loading.hide();
      if (response.data.success) {
        printJS({printable: response.data.data, type: 'pdf', base64: true});
        resetData();
      } else {
        dialog.value.show('error', 'Error al generar documento', response.data.message);
      }
    })
    .catch(error => {
      placeOrderDisabled.value = false;
      loading.hide();
      var errorMessage = '';
      if (typeof error == 'string') {
        errorMessage = error
      } else if (error.message) {
        errorMessage = error.message;
      } else if (error.response) {
        errorMessage = error.response.data.ErrorMessage;
      } else {
        errorMessage = 'Error de conectividad'
      }
      dialog.value.show('error', 'Error al colocar orden', errorMessage);
    });
  }
}

const resetData = () => {
  data_local.value = {
    id: 0,
    document: '',
    date: new Date(),
    dueDate: new Date(),
    documentTypeId: documentTypeInfo.id,
    documentType: documentTypeInfo.code,
    documentTypeName: documentTypeInfo.name,
    documentTypeFunction: documentTypeInfo.functionId,
    documentTypeFEL: documentTypeInfo.isFELDocument,
    description: '',
    comments: '',
    statusId: 'BOR',
    statusName: 'Borrador',
    partnerId: 0,
    customerCode: '',
    vat: 'CF',
    invoiceName: 'Consumidor final',
    invoiceAddress: '',
    invoiceCountryId: 0,
    invoiceStateId: 0,
    invoiceCityId: 0,
    invoiceZipCode: '',
    invoicePhones: '',
    shippingName: '',
    shippingAddress: '',
    shippingCountryId: 0,
    shippingStateId: 0,
    shippingCityId: 0,
    shippingZipCode: '',
    shippingPhones: '',
    salesmanId: 0,
    saleTypeId: 'CON',
    currencyId: 1,
    locationId: storeInfo.id,
    originWarehouseId: warehouseInfo.id,
    amount: 0,
    totalPaid: 0,
    uuid: '',
    relatedInvoices: 0,
    saleOrderId: 0,
    saleOrderName: '',
    saleOrderDate: new Date(),
    saleOrderAmount: 0,
    felToken: felProviderInfo.token,
    signURL: felProviderInfo.signURL,
    certificateURL: felProviderInfo.certificationURL,
    invoiceType: documentTypeInfo.felDocumentType,
    affiliationType: documentTypeInfo.felVATAffiliation,
    branch: storeInfo.felCode,
    senderEmail: storeInfo.email,
    senderVAT: storeInfo.vat,
    commercialName: storeInfo.commercialName,
    senderName: storeInfo.name,
    senderAddress: storeInfo.address,
    senderZipCode: storeInfo.zipCode,
    senderCity: storeInfo.cityName,
    senderState: storeInfo.stateName,
    senderCountry: storeInfo.countryCode,
    products: [],
    payments: [],
    cashPayments: []
  }
  paymentMethods.value.forEach(item => {
    data_local.value.cashPayments.push({
      id: item.id,
      code: item.code,
      name: item.name,
      paymentMethodId: item.id,
      paymentMethodCode: item.code,
      amount: 0
    });
  });
  placeOrderDisabled.value = false;
  reloadStock();
  currentTabIndex.value = 0;
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="live-preview">
              <div class="position-relative m-4">
                <div class="progress" style="max-height:  1px">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style="width: 50%"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <button
                  type="button"
                  class="
                    position-absolute
                    top-0
                    start-0
                    translate-middle
                    btn btn-sm
                    rounded-pill
                  "
                  :class="{'btn-primary': currentTabIndex == 0, 'btn-light': currentTabIndex != 0}"
                  style="width: 2rem; height: 2rem"
                >
                  1
                </button>
                <button
                  type="button"
                  class="
                    position-absolute
                    top-0
                    start-50
                    translate-middle
                    btn btn-sm btn-primary
                    rounded-pill
                  "
                  :class="{'btn-primary': currentTabIndex == 1, 'btn-light': currentTabIndex != 1}"
                  style="width: 2rem; height: 2rem"
                >
                  2
                </button>
                <button
                  type="button"
                  class="
                    position-absolute
                    top-0
                    start-100
                    translate-middle
                    btn btn-sm
                    rounded-pill
                  "
                  :class="{'btn-primary': currentTabIndex == 2, 'btn-light': currentTabIndex != 2}"
                  style="width: 2rem; height: 2rem"
                >
                  3
                </button>
              </div>
            </div>
            <div class="mt-4" v-if="currentTabIndex === 0">
              <h4 class="mb-3">Datos del colaborador</h4>
              <div class="row mb-3">
                <div class="form-floating col-3">
                  <input type="text" class="form-control rounded-0 border-0 border-bottom border-secondary bg-white"
                    id="customerCode" v-model="data_local.customerCode"
                    placeholder="Especifique código de colaborador"
                  />
                  <label for="customerCode">Código de colaborador</label>
                </div>
                <div class="form-floating col">
                  <input type="text" class="form-control rounded-0 border-0 border-bottom border-secondary bg-light"
                    id="shippingName" v-model="data_local.shippingName"
                    placeholder="Colaborador no identificado" />
                  <label for="shippingName">Nombre</label>
                </div>
              </div>
              <div class="row mb-1">
                <div class="form-floating col-3">
                  <input type="text" class="form-control rounded-0 border-0 border-bottom border-secondary bg-white"
                    id="vat" v-model="data_local.vat"
                    placeholder="NIT"
                    @blur="onVATBlur"
                  />
                  <label for="vat">NIT</label>
                </div>
                <div class="form-floating col">
                  <input type="text" class="form-control rounded-0 border-0 border-bottom border-secondary bg-light"
                    id="name" v-model="data_local.invoiceName" disabled
                    placeholder="Nombre a facturar" />
                  <label for="vatName">Nombre a facturar</label>
                </div>
              </div>
              <div class="d-flex row mt-5">
                <div class="col d-flex justify-content-between">
                  <b-button variant="danger" class="btn-label waves-effect waves-light" v-b-tooltip.hover
                    title="Limpiar datos de orden" @click="confirm.show('Confirme', '¿Está seguro de eliminar los datos ingresados en esta orden?')"><i class="las la-backspace label-icon"></i> Limpiar</b-button>
                  <b-button variant="success" class="btn-label waves-effect waves-light" v-b-tooltip.hover
                    title="Seleccionar productos" @click="toProducts"><i class="las la-arrow-right label-icon"></i> Siguiente</b-button>
                </div>
              </div>
            </div>
            <div class="p-2 bg-light" v-if="currentTabIndex === 1">
              <div class="d-flex row">
                <div class="d-flex col-2 mx-2 card">
                  <div class="card-header align-items-center d-flex justify-content-between">
                    <b-button variant="light" class="position-relative p-0 avatar-sm rounded-circle">
                      <h1 class="avatar-title bg-transparent text-reset">
                          <i class="las la-shopping-cart"></i>
                      </h1>
                      <span v-if="data_local.products.length > 0" class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-success p-2 fs-45">{{ data_local.products.length }}<span class="visually-hidden">Productos</span></span>
                    </b-button>
                    <b-button pill variant="secondary" class="justify-content-end waves-effect waves-light" v-b-tooltip.hover title="Clic para recalcular precio" @click="calculateAmount">Q{{ data_local.amount.toFixed(2) }}</b-button>
                  </div>
                  <div class="card-header align-items-center d-flex justify-content-center">
                    <b-button variant="success" class="btn-label waves-effect waves-light" v-b-tooltip.hover size="lg"
                    title="Finalizar orden" @click="toPayment"><i class="las la-arrow-right label-icon"></i> Finalizar</b-button>
                  </div>
                  <div class="card-body bg-light p-0">
                    <div v-for="item in data_local.products" :key="item.id">
                      <div class="card mb-1">
                        <div class="card-body">
                            <span class="card-title mb-2">{{ item.name }}</span>
                        </div>
                        <div class="card-footer justify-content-between container">
                          <div class="row align-middle">
                            <div class="col">
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                v-model="item.quantity"
                                min="1"
                                :max="maxQuantity"
                                @change="calculateAmount"
                              />
                            </div>
                            <div class="col mt-1">
                              x {{ item.price.toFixed(2) }}
                            </div>
                            <div class="col justify-content-end mt-1 text-end">
                              <b>{{ item.amount.toFixed(2) }}</b>
                            </div>
                            <b-button variant="danger" size="sm" class="col-1 btn-icon waves-effect waves-light" v-b-tooltip.hover title="Eliminar producto de la orden" @click="removeProduct(item)"><i class="las la-trash"></i></b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col me-2 card">
                  <div class="card-header align-items-start">
                    <div class="row mb-1">
                      <b-button-group>
                        <b-button v-for="item in categories" :key="item.id" :variant="item.id == currentCategory ? 'primary' : 'outline-primary'" data-bs-toggle="button" aria-pressed="false" @click="setCategory(item.id)">{{ item.name.toUpperCase() }}</b-button>
                      </b-button-group>
                    </div>
                    <div class="row">
                      <div class="input-group">
                        <input type="text" class="form-control form-control" placeholder="Buscar producto" v-model="search">
                        <b-button v-if="search != ''" variant="secondary" class="btn-icon waves-effect waves-light" v-b-tooltip.hover title="Limpiar frase de búsqueda" @click="search = ''"><i class="las la-times"></i></b-button>
                        <b-button variant="secondary" class="btn-icon waves-effect waves-light" v-b-tooltip.hover title="Buscar"><i class="las la-search"></i></b-button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body row bg-light">
                    <div v-for="item in productsList" :key="item.code" class="col-3">
                      <div class="card">
                        <div class="card-img-top img-fluid p-2">
                          <b-badge v-if="item.onHand > 3 && item.stockControl" pill variant="success">{{item.onHand}}</b-badge>
                          <b-badge v-if="item.onHand <= 0 && item.stockControl" pill variant="danger">{{item.onHand}}</b-badge>
                          <b-badge v-if="item.onHand > 0 && item.onHand < 4 && item.stockControl" pill variant="warning">{{item.onHand}}</b-badge>
                          <img
                            class="mx-auto d-block"
                            style="height: 175px; width: 175px"
                            :src="item.smallImage"
                            :alt="item.name"
                          />
                        </div>
                        <div class="card-body">
                            <h4 class="card-title mb-2">{{ item.name }}</h4>
                            <p class="card-text">{{ item.description }}</p>
                        </div>
                        <div class="card-footer justify-content-between container">
                          <div class="row align-middle">
                            <div class="col">
                              <h4>Q{{ item.price }}</h4>
                            </div>
                            <div class="col"/>
                            <div class="col">
                              <b-button variant="primary" class="btn-label waves-effect waves-light justify-content-end" v-b-tooltip.hover title="Agregar producto a orden" @click="addToOrder(item)"><i class="las la-cart-plus label-icon"></i> Agregar</b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex row mt-5">
                <div class="col d-flex justify-content-between">
                  <b-button variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover
                    title="Datos del colaborador" @click="currentTabIndex = 0"><i class="las la-arrow-left label-icon"></i> Anterior</b-button>
                  <b-button variant="danger" class="btn-label waves-effect waves-light" v-b-tooltip.hover
                    title="Cancelar orden" @click="confirm.show('Confirme', '¿Está seguro de cancelar esta orden?')"><i class="las la-backspace label-icon"></i> Cancelar</b-button>
                  <b-button variant="success" class="btn-label waves-effect waves-light" v-b-tooltip.hover
                    title="Finalizar orden" @click="toPayment"><i class="las la-arrow-right label-icon"></i> Siguiente</b-button>
                </div>
              </div>
            </div>
            <div class="p-2 bg-light" v-if="currentTabIndex === 2">
              <div class="d-flex row">
                <div class="d-flex col-2 mx-2 card">
                  <div class="card-header align-items-center d-flex justify-content-between">
                    <b-button variant="light" class="position-relative p-0 avatar-sm rounded-circle">
                      <h1 class="avatar-title bg-transparent text-reset">
                          <i class="las la-shopping-cart"></i>
                      </h1>
                      <span v-if="data_local.products.length > 0" class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-success p-2 fs-45">{{ data_local.products.length }}<span class="visually-hidden">Productos</span></span>
                    </b-button>
                    <b-button pill variant="secondary" class="justify-content-end waves-effect waves-light" >Q{{ data_local.amount.toFixed(2) }}</b-button>
                  </div>
                  <div class="card-body p-0">
                    <div v-for="item in data_local.products" :key="item.code">
                      <div class="card border mb-1">
                        <div class="card-body">
                            <span class="card-title mb-2">{{ item.name }}</span>
                        </div>
                        <div class="card-footer bg-light justify-content-between container">
                          <div class="row align-middle">
                            <div class="col">
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                v-model="item.quantity"
                                min="1"
                                :max="maxQuantity"
                                readonly
                              />
                            </div>
                            <div class="col mt-1">
                              x {{ item.price.toFixed(2) }}
                            </div>
                            <div class="col justify-content-end mt-1 text-end">
                              <b>{{ item.amount.toFixed(2) }}</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col me-2 card">
                  <div class="card-header align-items-start">
                    <h3>Formas de pago</h3>
                  </div>
                  <div class="card-body bg-light">
                    <div v-for="item in data_local.cashPayments" :key="item.code" class="container">
                      <div class="row mb-2" v-show="(item.code != 'DM' || data_local.active == 'X') && (item.code != 'AC' || data_local.autoSale == 'X')">
                        <div class="col bg-secondary text-white fw-bold border rounded align-middle fs-5 pt-2">
                          {{ item.name }}
                        </div>
                        <div class="col-2">
                          <input
                            type="number"
                            class="form-control form-control-lg text-end"
                            v-model="item.amount"
                            min="0"
                            max="1000"
                            @change="calculateTotalPaid(item)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-4 me-2 card">
                  <div class="card-header align-items-start">
                    <h3>Resumen de orden</h3>
                  </div>
                  <div class="card-body">
                    <div class="form-floating mb-1">
                      <input type="text" class="form-control border-secondary bg-light"
                        id="paymentCode" v-model="data_local.customerCode" disabled />
                      <label for="paymentCode">Código de colaborador</label>
                    </div>
                    <div class="form-floating mb-1">
                      <input type="text" class="form-control border-secondary bg-light"
                        id="paymentName" v-model="data_local.shippingName" disabled />
                      <label for="paymentName">Nombre</label>
                    </div>
                    <div class="form-floating mb-1">
                      <input type="text" class="form-control border-secondary bg-light"
                        id="paymentVat" v-model="data_local.vat" disabled />
                      <label for="paymentVat">NIT</label>
                    </div>
                    <div class="form-floating mb-1">
                      <input type="text" class="form-control border-secondary bg-light"
                        id="paymentVatName" v-model="data_local.invoiceName" disabled />
                      <label for="paymentVatName">Nombre a facturar</label>
                    </div>
                    <hr/>
                    <div class="row px-2">
                      <span class="col fs-5">Total a pagar</span>
                      <span class="col-3 fs-5 fw-bold text-end text-secondary">Q{{data_local.amount.toFixed(2)}}</span>
                    </div>
                    <div class="row px-2">
                      <span class="col fs-5">Total pagado</span>
                      <span class="col-3 fs-5 fw-bold text-end">Q{{data_local.totalPaid.toFixed(2)}}</span>
                    </div>
                    <div class="row px-2">
                      <span class="col fs-5">Cambio</span>
                      <span class="col-3 fs-5 fw-bold text-end text-success">Q{{change.toFixed(2)}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex row mt-5">
                <div class="col d-flex justify-content-between">
                  <b-button variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover
                    title="Productos" @click="currentTabIndex--"><i class="las la-arrow-left label-icon"></i> Anterior</b-button>
                  <b-button variant="danger" class="btn-label waves-effect waves-light" v-b-tooltip.hover
                    title="Cancelar orden" @click="confirm.show('Confirme', '¿Está seguro de cancelar esta orden?')"><i class="las la-backspace label-icon"></i> Cancelar</b-button>
                  <b-button variant="success" class="btn-label waves-effect waves-light" v-b-tooltip.hover :disabled="placeOrderDisabled"
                    title="Finalizar orden" @click="placeOrder"><i class="las la-cash-register label-icon"></i> Colocar orden</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog ref="dialog"/>
    <Dialog ref="dialogPlacedOrder" @accept="resetData"/>
    <Confirm ref="confirm" @accept="resetData"/>
  </Layout>
</template>
